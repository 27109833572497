<template>
	<div class="component-tabs-quantity" style="padding: '20px';">
		<!--Modal de criação -->
		<b-modal id="factor-modal" v-model="showModal" title="Novo fator" size="lg" hide-footer hide-header centered>
			<h5>Novo fator</h5>
			<div>	
				<div style="display: flex; gap: 10px; margin-right: 10px;">
					<b-col md="4">
						<label for="">Código</label>
						<select v-if="!isEditingFactor" style="display: flex; min-height: 38px; max-width: 255px;" id="product-select" v-model="newDataObj" @change="selectProduct(newDataObj)">
							<option v-for="product in products" :key="product.id" :value="product">
								{{ product.code }} - {{ product.name }}
							</option>
						</select>
						<select v-if="isEditingFactor" style="display: flex; min-height: 38px; max-width: 255px;" id="product-select" v-model="newDataObj.product_id" @change="selectProduct(newDataObj)">
							<option v-for="product in products" :key="product.id" :value="product.id">
								{{ product.code }} - {{ product.name }}
							</option>
						</select>
					</b-col>
					<b-col md="4">
						<label class="label-disabled" for="">Produto</label>
						<b-form-input class="input-disabled" disabled v-model="newDataObj.product_name"></b-form-input>
					</b-col>
					<b-col md="4">
						<label class="label-disabled" for="">Linha</label>
						<b-form-input class="input-disabled" v-model="newDataObj.line" disabled></b-form-input>
					</b-col>
				</div>
				<div style="display: flex; gap: 10px; margin-right: 10px;">
					<b-col md="4">
						<label for="">Fator</label>
						<b-form-input v-model="newDataObj.factor"></b-form-input>
					</b-col>
					<b-col md="4">
						<label for="">Data inicial</label>
						<b-form-datepicker id="example-datepicker1" placeholder="" v-model="newDataObj.date_initial" class="mb-2"
						:locale="'pt-BR'"
						:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
					</b-col>
					<b-col md="4">
						<label for="">Data final</label>
						<b-form-datepicker id="example-datepicker2" placeholder="" v-model="newDataObj.date_final" class="mb-2"
						:locale="'pt-BR'"
						:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
					</b-col>
				</div>
			</div>
			<div style="display: flex; align-items: center; justify-content: flex-end;">					
				<b-button style="margin-right: 10px;" @click="closeModal"> Cancelar </b-button>
				<b-button v-if="!isEditingFactor" @click="saveFactor"> Salvar </b-button>
				<b-button v-if="isEditingFactor" @click="updateFactor"> Editar </b-button>
			</div>
		</b-modal>
		<!--Filtros e cabeçalho -->
		<b-row>
			<div style="display: flex; flex-direction: column; width: 100%;">
			<b-col md="6">
				<h5>Listagem de fatores</h5>
			</b-col>
			<b-col md="6" class="col-add-fator">
				<div style="display: flex; width: 100%; justify-content: flex-end; align-items: center;">
					<span class="">
						<font-awesome-icon :icon="['fas', 'eye']" class="" />
						Exibir somente ativos?
					</span>
					<label class="switch">
						<input type="checkbox" v-model="checkbox" @click="toggleCheckbox" />
						<div class="slider round"></div>
					</label>
				</div>
				<div style="display: flex; width: 100%; justify-content: flex-end; align-items: center; gap: 10px; margin-top: 10px;">
					<b-button class="add-fator" @click="openModal"
						v-if="userLoggedPermission.permissionfactorm == 1 || userLogged.role == 'Administrador'">
						<font-awesome-icon :icon="['fas', 'plus']" class="" />
						Novo fator
					</b-button>
					<b-button class="add-fator" @click="synchronizeFactors"
						v-if="userLoggedPermission.permissionfactorm == 1 || userLogged.role == 'Administrador'">
						<font-awesome-icon :icon="['fas', 'arrow-down']" class="" />
						Sincronizar
					</b-button>
					<b-button class="add-fator" @click="clearFactors"
						v-if="userLoggedPermission.permissionfactorm == 1 || userLogged.role == 'Administrador'">
						<font-awesome-icon :icon="['fas', 'trash-alt']" class="" />
						Limpar fatores
					</b-button>
				</div>
			</b-col>

			</div>
			<b-col md="12" class="col-search">
				<div class="form">
					<b-form-input placeholder="Pesquisar" v-model="search"></b-form-input>
					<button type="submit">Search</button>
				</div>
			</b-col>
			<div class="col-md-12 card">
				<b-table :items="itemsFiltradosPaginados" :fields="fieldsProduct" responsive="" class="table-factors">
					<template #cell(date_initial)="data">
						{{ data.item.date_initial | dateFormat }}
					</template>
					<template #cell(date_final)="data">
						{{ data.item.date_final | dateFormat }}
					</template>
					<template #cell(active)="">
						Sim
					</template>
					<template #cell(actions)="data">
						<b-button @click="editFactor(data.item)" style="text-decoration: none; color: black"
							v-if="userLoggedPermission.permissionfactorm == 1 || userLogged.role == 'Administrador'">
							<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
						</b-button>
						<b-button @click="removeFactor(data.item)"
							style="text-decoration: none; color: black"
							v-if="userLoggedPermission.permissionfactorm == 1 || userLogged.role == 'Administrador'">
							<h6><font-awesome-icon :icon="['fas', 'trash-alt']" /></h6>
						</b-button>
					</template>
				</b-table>
				<p class="line"></p>
			</div>
			<b-col md="1"></b-col>
			<b-col md="6"></b-col>
		</b-row>

		<!--Lista de Fatores -->
		<b-row class="paginations-options1 mt-3">
			<b-col md="12" class="mt-3">
				<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import moment from "moment";

export default {
	name: "FactorIndexComponent",
	data() {
		return {
			factors: [],
			checkbox: true,
			perPage: 6,
			currentPage: 1,
			search: '',
			userLoggedPermission: localStorage.getItem("userPermission")
				? JSON.parse(localStorage.getItem("userPermission"))
				: null,
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,	
			paginaAtual: 1,
			itensPorPagina: 5,
			showModal: false,
			dataObj: {
				id: null,
				code: '',
				product: '',
				line: '',
				factor: '',
				date_initial: '',
				date_final: '',
				user: '',
				current_date: '',
				product_id: null,
				product_name: '',
				user_id: ''
			},
			newDataObj: {
				id: null,
				code: '',
				product: "",
				line: '',
				factor: '',
				date_initial: '',
				date_final: '',
				user: '',
				current_date: '',
				product_id: null,
				product_name: '',
				user_id: '',
				name: ''
			},
			products: [],
			selectedFactor: "",
			obj: this.$route.params.dataObj,
			simulation_id: null,
			isEditingFactor: false
		};
	},

	mounted() {
		this.setSimulation();
		this.getObjParams();
		this.getFactors();
		this.getProducts();
		this.getData();
		this.products.map(function (b) {
			return b.book_info = b.name + '-' + b.code;
		});
	},

	methods: {
		async synchronizeFactors() {
            this.$swal.showLoading();
			await this.$http
				.get(`factors_simulation/synchronize`, { params: { simulation_id: this.simulation_id }})
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.factors = response.data.factor_simulations;
						this.$swal.close();
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$swal.close();
				});
		},
		async clearFactors() {
            this.$swal.showLoading();
			await this.$http
			.post(`factors_simulation/clear`, { simulation_id: this.simulation_id })
			.then((response) => {
				if (response.data.error) {
					this.goError(response.data.message);
				} else {
					this.factors = response.data.factor_simulations;
					this.$swal.close();
				}
			})
			.catch(function (error) {
				console.log(error);
				this.$swal.close();
			});
		},
		// Modal create methods
		clearNewFactorModal(){
			this.newDataObj = {
				id: '',
				code: '',
				product: "",
				line: '',
				factor: '',
				date_initial: '',
				date_final: '',
				user: '',
				current_date: '',
				product_id: '',
				product_name: '',
				user_id: '',
				name: ''
			};
			this.isEditingFactor = false
		},

		setSimulation(){
			const url = this.$route.path;
			const simulationMatch = url.match(/simulation\/(\d+)/);
			if (simulationMatch && simulationMatch[1]) {
				this.simulation_id = simulationMatch[1];
			}
		},

		openModal() {
			if (!this.isEditingFactor) {
				this.clearNewFactorModal()				
			}
			this.showModal = true;
		},

		closeModal() {
			this.showModal = false;
			this.clearNewFactorModal()
		},

		async getProducts() {
            this.$swal.showLoading();
			await this.$http
				.get(`/products/allProducts`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.products = response.data.productsFactor;
						this.$swal.close();
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$swal.close();
				});
		},

		getObjParams() {
			if (this.$route.params.dataObj) {
				this.selectedFactor = this.obj.factor;
				this.dataObj.code = this.selectedFactor.code
				this.dataObj.product = this.selectedFactor.name
				this.dataObj.product_name = this.selectedFactor.name
				this.dataObj.line = this.selectedFactor.line_product
				this.dataObj.factor = this.selectedFactor.factor
				this.dataObj.product_id = this.selectedFactor.product_id
				this.dataObj.date_initial = this.selectedFactor.date_initial
				this.dataObj.date_final = this.selectedFactor.date_final
				this.dataObj.current_date = this.selectedFactor.updated_at
				this.dataObj.user = this.selectedFactor.user_name
				this.dataObj.user_id = this.selectedFactor.user_id
				this.dataObj.id = this.selectedFactor.id
			}
		},

		getData() {
			if (this.selectedFactor) {
				this.dataObj.user = this.selectedFactor.user_name
			}
			this.dataObj.user = this.userLogged.name
			this.dataObj.current_date = moment().format("DD/MM/YYYY");
		},

		async selectProduct(item) {
			if (this.isEditingFactor) {
				const targetItem = this.products.find(e=> e.id == item.product_id)

				this.newDataObj.product = targetItem?.name || '';
				this.newDataObj.product_name = targetItem?.name || '';
				this.newDataObj.line = targetItem?.line_product || '';
				this.newDataObj.product_id = targetItem?.id || null;
			}
			else{
				this.newDataObj.product = item?.name || '' ;
				this.newDataObj.product_name = item?.name || '' ;
				this.newDataObj.line = item?.line_product || '' ;
				this.newDataObj.product_id = item?.id || null;
			}

		},
		async saveFactor() {
			if (this.newDataObj.code == '' || this.newDataObj.factor == '') {
				this.goError("Preencha os campos!");
			} else {
				const formData = new FormData();
				formData.append("code", this.newDataObj.code);
				formData.append("product", this.newDataObj.product_id);
				formData.append("product_name", this.newDataObj.product);
				formData.append("factor", this.newDataObj.factor);
				formData.append("date_initial", this.newDataObj.date_initial);
				formData.append("date_final", this.newDataObj.date_final);
				formData.append("user", this.userLogged.id);
				formData.append("simulation_id", this.simulation_id);

				this.$http
					.post(`/factors_simulation/store`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							this.closeModal()
							this.getFactors()
						}
					})
					.catch((error) => {
						this.goError(error.response.data.message);
						console.log(error.response.data.message);
					});
			}
		},

		async updateFactor(){
			if (this.newDataObj.code == '' || this.newDataObj.factor == '') {
				this.goError("Preencha os campos!");
			} else {
				console.log("newDataObj to update", this.newDataObj)
				const formData = new FormData();
				formData.append("code", this.newDataObj.code);
				formData.append("product", this.newDataObj.product_id);
				formData.append("product_name", this.newDataObj.product.name);
				formData.append("factor", this.newDataObj.factor);
				formData.append("date_initial", this.newDataObj.date_initial);
				formData.append("date_final", this.newDataObj.date_final);
				formData.append("user", this.userLogged.id);
				formData.append("simulation_id", this.simulation_id);
				formData.append("id", this.newDataObj.id);

				this.$http
					.post(`/factors_simulation/update`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							console.log("goSuccess!")
							this.closeModal()
							console.log("closeModal!")
							this.getFactors()
							console.log("getFactors!")
						}
					})
					.catch((error) => {
						this.goError(error.response.data.message);
						console.log(error.response.data.message);
					});
			}
		},

		// Page methods

		toggleCheckbox() {
			this.checkbox = !this.checkbox;
			this.$emit("setCheckboxVal", this.checkbox);
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getFactors() {
			await this.$http
				.get(`/factors_simulation`, { params: { simulation_id: this.simulation_id }})
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.factors = response.data.factor_simulations;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		editFactor(params) {
			this.isEditingFactor = true;
			this.newDataObj = {
				id: params.id,
				code: params.code,
				factor: params.factor,
				date_initial: params.date_initial,
				date_final: params.date_final,
				user: params.user_id,
				user_id: params.user_id,
				current_date: '',
				product_id: params.product_id,
				line: params.line_product,
				product_name: params.name,
				product: this.products.find(product => product.id === params.product_id) || null
			};
			this.openModal();
		},

		async removeFactor(item) {
			let formData = new FormData();
			formData.append("id", item.id);
			await this.$swal
			.fire({
				text:
					"Você está excluindo o fator " + item.factor + " como o código " + item.code,
				title: "Confirmar exclusão de fator?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "SIM, EXCLUIR",
				cancelButtonText: `CANCELAR`,
				confirmButtonColor: "#d33",
				cancelButtonColor: "#d33",
				customClass: {
					cancelButton:
						"btn btn-light border border-secondary rounded-pill px-md-5",
					confirmButton: "btn btn-danger rounded-pill px-md-5",
				},
				buttonsStyling: false,
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
					.post(`/factors_simulation/delete`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							this.getFactors();
						}
					})
					.catch((error) => {
						this.goError(error.response.data.message);
						console.log(error.response.data.message);
					});
				}
			});
		},
	},

	computed: {
		fieldsProduct: function () {
			let fields = [
				{ key: "code", label: "Código do produto" },

				{
					key: "name",
					label: "Nome do produto",
				},

				{
					key: "line_product",
					label: "Linha",
				},

				{
					key: "factor",
					label: "Fator",
				},

				{
					key: "date_initial",
					label: "Data inícial",
				},

				{
					key: "date_final",
					label: "Data final",
				},

				{
					key: "active",
					label: "Ativo atual",
				},

				{
					key: "actions",
					label: "",
				},
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.factors.slice(start, end);
		},

		filteredList() {
			if (this?.factors) {
				return this.factors.filter((factor) => {
					const nameMatch = factor.name.toLowerCase().includes(this.search.toLowerCase());
					const codeMatch = factor.code.toString().toLowerCase().includes(this.search.toLowerCase());
					// Se você deseja filtrar por ambos name e code, use && (E lógico)
					// Se você deseja filtrar por name OU code, use || (OU lógico)
					return nameMatch || codeMatch;
				});				
			}
			return []
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			if (this?.factors) {
				return this.factors.filter(item => item.code.toLowerCase().includes(this.search.toLowerCase()) || item.name.toLowerCase().includes(this.search.toLowerCase()));
			}
			return []
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD.MM.YYYY");
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
