<template>
	<div class="component-tabs-quantity">
		<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 10px;">
			<div class="col-label">
				<span> Mês </span>
				<select style="display: flex; min-height: 20px;" id="month-select" v-model="monthSelected">
					<option v-for="month in months" :key="month.value" :value="month.value" style="font-size: 0.9rem">
						{{ month.label }}
					</option>
				</select>
			</div>
			<div style="display: flex; justify-content: flex-end ; align-items: center; margin-right: 20px;">
				<b-button class="button" @click="getProductsForSellSimulation()" style="width: auto;;"> Carregar Produtos de Forecasts </b-button>
			</div>
		</div>
		<!-- LISTA DE PRODUTOS E DERIVAÇÕES -->
		<b-row>
			<b-col md="12" class="title">
				<p><strong> Produtos </strong></p>
			</b-col>
		</b-row>
		<b-row  class="table_products">
			<b-col md="12">
				<div v-for="(product, index) in itemsFiltradosPaginados" :key="index">
					<div class="responsive table-responsive">
						<label
							for=""
							class="label-product" @click="findProduct(product, index)" 
						>
							{{ product.code }} - {{ product.name }}
								<font-awesome-icon :icon="['fas', 'arrow-circle-down']" />
						</label>
						<div 
							:id="'product-scenario-' + product.id"
							class=" item-content" :class="{ 'open': isOpen[index] }"
						>
							<table class="table-products table responsive">
								<thead>
									<tr>
										<th class="cod-der">
											Derivação
										</th>
										<th class="der">
											Quantidade
										</th>
										
									</tr>
								</thead>
							
								<tbody>
									<tr v-for="(item, itemIndex) in product.items" :key="itemIndex">
										<td class="cod-der">
											{{ item.derivation }}
										</td>
										<td class="cod-der">
											<input style="width: 100%;" type="text" v-model="item.qtd" />
										</td>
										<td class="cod-der">
											<button style="padding-left: 5px; padding-right: 5px; border-radius: 5px;" @click="updateSimulationForecastItem(item, product)">Atualizar</button>
										</td>
									
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</b-col>

			<b-col>
				<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina"
					style="float: right;"></b-pagination>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// import lineChart from "./LinesChart";
import moment from "moment";

export default {
	name: "SellSimulationComponent",
	components: {
		// lineChart,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			openCollapse: null,
			currentYear: new Date().getFullYear(),
			view_months: true,
			view_months_btn: false,
			view_months_btn_clear: false,
			productsCode: [],
			optionsPie: [],
			dataAtingimento: [],
			obj: this.$route.params,
			dataObj: {
				name: "",
				dateRef: "",
				dateCreate: new Date().toLocaleDateString(),
				user_id: "",
				observation: "",
				sell:{
					value_jan: "",
					value_feb: "",
					value_mar: "",
					value_apr: "",
					value_may: "",
					value_jun: "",
					value_jul: "",
					value_aug: "",
					value_sep: "",
					value_oct: "",
					value_nov: "",
					value_dec: "",
					id: "",
					cod_product: "",
					derivation: "",
					year: ""
				},
			},
			sells: [],
			active: null,
			disabled: false,
			openIndex: null,
			paginaAtual: 1,
			itensPorPagina: 10,
			growth: '',
			values_sell: [],
            isOpen: [],
			monthSelected: null,
			months: [],
			date_atual: moment(),
			resultado: {},
			resultado_growth: {},
			derivations: [],
            mediaTotaisPorDerivacao: {},
			simulation_id: null
		};
	},
	mounted() {
		this.setSimulation()
		this.getProductsForSellSimulation();
		this.getSells();
		this.generateMonthNames();

		this.months = [...Array(12)].map((_, index) => {
			const currentMoment = moment(this.date_atual).add(index, 'M').startOf('month');
			return {
				label: currentMoment.locale('pt').format('MMM, YYYY'),
				value: currentMoment.format('YYYYMMDD'),
			};
		});
		if (this.months.length > 0) {
			this.monthSelected = this.months[0].value;
		}
	},
	methods: {

		setSimulation(){
			const url = this.$route.path;
			const simulationMatch = url.match(/simulation\/(\d+)/);
			if (simulationMatch && simulationMatch[1]) {
				this.simulation_id = simulationMatch[1];
			}
		},
		generateMonthNames() {
			moment.locale('pt-br');

			const currentDate = moment().startOf('month'); // Iniciar no primeiro dia do mês atual
			const numberOfMonths = 12; // Pode ajustar conforme necessário

			for (let i = 0; i < numberOfMonths; i++) {
				const monthYear = currentDate.clone().add(i, 'month').format('MMM_YYYY').toLowerCase();
				const month = currentDate.clone().add(i, 'month').format('MMM').toLowerCase();
				const year = currentDate.clone().add(i, 'month').format('YYYY').toLowerCase();
				this.$set(this.values_sell, monthYear, 0);
				this.$set(this.resultado, monthYear, 0);
				this.$set(this.resultado_growth, monthYear, {valor:0, mes: month, ano: year});
			}
		},

		closeProductLists(){
			this.isOpen = []
		},

		async findProduct(product, index){
			const isOpen = this.isOpen[index];

			if (isOpen) {
				this.isOpen = this.isOpen.map(() => false);
			} else {
				this.isOpen = this.isOpen.map(() => false);
				this.$set(this.isOpen, index, true);
			}

			let formData = new FormData();
			formData.append("product_code", product.code);
			formData.append("year", this.currentYear);

			this.$http
				.post(`/scenarios/sell/find_product`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.values_sell = response.data.findProduct
						this.derivations = response.data.derivacoes

						for (const month in this.values_sell) {
							if (Object.prototype.hasOwnProperty.call(this.values_sell, month)) {
								const arrayForMonth = this.values_sell[month];
								console.log(`Array para ${month}:`, arrayForMonth);
							}
						}

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async updateSimulationForecastItem(item, product) {
			this.$swal.showLoading();
			await this.$http
			.post(`/s&op/update_simulation_forecast_item`, {
				simulation_id: this.simulation_id,
				forecast_products_id: item.id,
				qtd: item.qtd,
			})
			.then((response) => {
				if (response.data.error) {
					this.goError(response.data.message);
				} else {
					this.goSuccess(response.data.message);
					// this.getProductsForSellSimulation();
					this.$swal.close();
				}
			})
			.catch(function (error) {
				console.log(error);
				this.$swal.close();
			});
			console.log('Item/Process: ', {item, product});			
		},

		async getProductsForSellSimulation() {
            this.$swal.showLoading();
			await this.$http
                .get(`/s&op/simulation_sell_products`,{
					params: {
						monthSelected: this.monthSelected,
						simulation_id: this.simulation_id
					}
				})
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.productsCode = response.data.products;
						this.$swal.close();
						this.closeProductLists();
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$swal.close();
				});
		},

		goSuccess(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 4000,
                icon: "success",
                title: "Sucesso!",
                text: message ? message : "Cadastro Atualizado.",
            });
        },

        goError(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "Atenção!",
                text: message ? message : "Já existe um usuário com este e-mail.",
            });
        },

		async getSells() {
			await this.$http
				.get(`/scenarios/simulation/${this.obj.id}/sell`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						let sell = response.data.sell_simulation;
						this.sells = sell
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
	},

	computed: {
		getFormattedData() {
			const formattedData = {};

			// this.growth.forEach(item => {
			// 	const fieldName = `${item.month_year}`;
			// 	formattedData[fieldName] = {valor: item.growth, mes: item.date, ano: item.year}; // Valor inicial de 0
			// })

			return formattedData;
		},
		totalItensFiltrados() {
			return this.productsCode.length;
		},

		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.productsCode.slice(inicio, fim);
		},
		orderedResultado() {
			const sortedKeys = Object.keys(this.resultado).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado[key];
			});

			return ordered;
		},

		orderedFactors() {
			let factors = 0;
			if(this.values_sell == ''){
				factors = this.resultado
			} else {
				factors = this.values_sell
			}
			const sortedKeys = Object.keys(factors).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = factors[key];
			});

			return ordered;
		},

		orderedFactorsGrowth() {
			const sortedKeys = Object.keys(this.resultado_growth).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado_growth[key].valor;
			});

			return ordered;
		},

		values_chart() {
			const sortedKeys = Object.keys(this.getFormattedData).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo array ordenado apenas com os valores
			const orderedValues = sortedKeys.map(key => this.getFormattedData[key].valor);

			return orderedValues;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
