<template>
	<div class="component-tabs-quantity">
		<div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 10px;">
			<div class="col-label">
				<span> Mês </span>
				<select style="display: flex; min-height: 20px;" id="month-select" v-model="monthSelected">
					<option v-for="month in months" :key="month.value" :value="month.value" style="font-size: 0.9rem">
						{{ month.label }}
					</option>
				</select>
			</div>
			<div class="col-label">
				<span> Produto </span>
				<select style="display: flex; min-height: 20px; max-width: 300px;" id="product-select" v-model="productSelected">
					<option v-for="product in productOptions" :key="product.key" :value="product.key" style="font-size: 0.9rem">
						{{ product.label }}
					</option>
				</select>
				<button 
					v-if="productSelected" 
					@click="clearSelection" 
					style="align-self: flex-start; font-size: 0.8rem; cursor: pointer; border-radius: 7px;"
				>
					X
				</button>
			</div>
		</div>
		<div style="display: flex; justify-content: flex-end ; align-items: center; margin-right: 20px;">
			<b-button class="button" @click="getPocessesForSimulation(true)"> Carregar OCs </b-button>
		</div>
		<b-row>
			<b-col md="12" class="title">
				<p><strong> Ordens de Compra </strong></p>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12">
				<div>
					<div class="table_products">
						<div class="table-header">
						</div>
						<div class="table-body">
							<div  v-for="(process, index) in itemsFiltradosPaginados"  :key="process.id"  class="table-row" >
								<label
									for=""
									class="label-product" 
									@click="findProcess(process, index)" 
								>
									<div style="display: flex; flex-direction: row; flex: 1; justify-content: space-between;">
										<div class="table-cell">{{ process.tipImp }} {{ process.id }} - {{ process.desSta }} - {{ process.nomFor }}</div>
										<div style="display: flex; justify-content: space-evenly;" class="table-cell">
											<div>Data ETD: {{ process.datEtd }} </div>
											<div>Data ETV: {{ process.datEtv }}</div>
										</div>
									</div>
									<font-awesome-icon :icon="['fas', 'arrow-circle-down']" />
								</label>
								<div 
									:id="'process-scenario-' + process.id"
									class="item-content" :class="{ 'open': isOpen[index] }"
								>
									<table class="table-products table responsive">
										<thead>
											<div style="display: flex; padding: 10px;">
												<div style="margin-right: 20px; gap: 5px; display: flex; align-items: center;">
													<label for="data-etd">Data ETD: </label>
													<input type="text" v-model="process.datEtd" :id="'data-etd-' + process.id" />
												</div>
												<div style="margin-right: 20px; gap: 5px; display: flex; align-items: center;">
													<label for="data-etv">Data ETV: </label>
													<input type="text" v-model="process.datEtv" :id="'data-etv-' + process.id" /> 
												</div> 
												<button class="button" @click="updateSimulationProcess(process)">Atualizar</button>
											</div>
											<tr style="display: flex; flex-direction: row; padding-left: 10px;">
												<div class="table-cell"><strong>Código</strong></div>
												<div class="table-cell"><strong>Descrição</strong></div>
												<div class="table-cell"><strong>Derivação</strong></div>
												<div class="table-cell"><strong>Quantidade</strong></div>
												<div class="table-cell"><strong>Vlr. Unitário</strong></div>
												<div class="table-cell"><strong>Atualizar</strong></div>
											</tr>
										</thead>
										<tbody>
											<div  v-for="(item) in process.items"  :key="item.id" style="padding-left: 10px;">
												<tr style="display: flex; flex-direction: row; padding: 0;">
													<div style="display: flex; width: 16.6%;; padding: 8px;" class="table-cell">{{ item.codPro }}</div>
													<div style="display: flex; width: 16.6%;; padding: 8px;" class="table-cell">{{ item.desPro }}</div>
													<div style="display: flex; width: 16.6%;; padding: 8px;" class="table-cell">{{ item.codDer }}</div>
													<div style="display: flex; width: 16.6%; padding: 8px;" class="table-cell">
														<input style="width: 100%;" type="text" v-model="item.qtdIte" />
													</div>
													<div style="display: flex; width: 16.6%; padding: 8px;" class="table-cell">
														<input style="width: 100%;" type="text" v-model="item.vlrUni" />
													</div>
													<div style="display: flex; width: 16.6%; padding: 8px;">
														<button style="padding-left: 5px; padding-right: 5px; border-radius: 5px;" @click="updateSimulationProcessItem(item, process)">Atualizar</button>
													</div>
												</tr>
											</div>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row class="table_products">
			<b-col md="12">
			</b-col>
			<b-col>
				<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina"
					style="float: right; margin-top: 10px"></b-pagination>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// import lineChart from "./LinesChart";
import moment from "moment";

export default {
	name: "QuantityComponent",
	components: {
		// lineChart,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			openCollapse: null,
			currentYear: new Date().getFullYear(),
			view_months: true,
			view_months_btn: false,
			view_months_btn_clear: false,
			optionsPie: [],
			dataAtingimento: [],
			obj: this.$route.params,
			dataObj: {
				name: "",
				dateRef: "",
				dateCreate: new Date().toLocaleDateString(),
				user_id: "",
				observation: "",
				purchase: {
					value_jan: "",
					value_feb: "",
					value_mar: "",
					value_apr: "",
					value_may: "",
					value_jun: "",
					value_jul: "",
					value_aug: "",
					value_sep: "",
					value_oct: "",
					value_nov: "",
					value_dec: "",
					id: "",
					cod_product: "",
					derivation: "",
					year: ""
				},
				// growth: {
				// 	value_jan: "",
				// 	value_feb: "",
				// 	value_mar: "",
				// 	value_apr: "",
				// 	value_may: "",
				// 	value_jun: "",
				// 	value_jul: "",
				// 	value_aug: "",
				// 	value_sep: "",
				// 	value_oct: "",
				// 	value_nov: "",
				// 	value_dec: "",
				// }
			},

			purchases_qtd: [],
			editingIndex: -1,
			active: null,
			disabled: false,
			openIndex: null,
			paginaAtual: 1,
			itensPorPagina: 10,
			// growth: '',
            isOpen: [],
			date_atual: moment(),
			costInitial: [],
			// months_growth: '',
			resultado: {},
			resultado_growth: {},
			values_sell: {},
			monthSelected: null,
			months: [],
			productSelected: null,
			productOptions: [],
			purchaseOrders: [],
			simulationProductList: [],
			simulation_id: null,
		};
	},
	mounted() {
		this.setSimulation()
		this.getPurchaseQtds();
		this.generateMonthNames();
		this.getProductsOptions();

		this.months = [...Array(12)].map((_, index) => {
			const currentMoment = moment(this.date_atual).add(index, 'M').startOf('month');
			return {
				label: currentMoment.locale('pt').format('MMM, YYYY'),
				value: currentMoment.format('YYYYMMDD'),
			};
		});
		if (this.months.length > 0) {
			this.monthSelected = this.months[0].value;
		}
		this.getPocessesForSimulation();
	},
	methods: {
		async findProcess(process, index){
			const isOpen = this.isOpen[index];

			if (isOpen) {
				this.isOpen = this.isOpen.map(() => false);
			} else {
				this.isOpen = this.isOpen.map(() => false);
				this.$set(this.isOpen, index, true);
			}
		},

		async updateSimulationProcess(process){
            this.$swal.showLoading();
			await this.$http
			.post(`/s&op/update_simulation_process`, {
				process_id: process.id,
				datEtd: process.datEtd,
				datEtv: process.datEtv,
				simulation_id: this.simulation_id,
			})
			.then((response) => {
				if (response.data.error) {
					this.goError(response.data.message);
				} else {
					this.goSuccess(response.data.message);
					// this.getPocessesForSimulation();
				}
			})
			.catch(function (error) {
				console.log(error);
				this.$swal.close();
			});
		},

		async updateSimulationProcessItem(item, process){
            this.$swal.showLoading();
			let simulation_process_id = null
			if(process.simulationProcesses && process.simulationProcesses.id){
				simulation_process_id = process.simulationProcesses.id
			}
			await this.$http
			.post(`/s&op/update_simulation_process_item`, {
				simulation_id: this.simulation_id,
				simulation_process_id,
				process_id: process.id,
				process_item_id: item.id,
				qtdIte: item.qtdIte,
				vlrUni: item.vlrUni,
			})
			.then((response) => {
				if (response.data.error) {
					this.goError(response.data.message);
				} else {
					this.goSuccess(response.data.message);
					// this.getPocessesForSimulation();
					// this.$swal.close();
				}
			})
			.catch(function (error) {
				console.log(error);
				this.$swal.close();
			});
		},

		generateMonthNames() {
			moment.locale('pt-br');
			const currentDate = moment().startOf('month'); // Iniciar no primeiro dia do mês atual
			const numberOfMonths = 12; // Pode ajustar conforme necessário

			for (let i = 0; i < numberOfMonths; i++) {
				const monthYear = currentDate.clone().add(i, 'month').format('MMM_YYYY').toLowerCase();
				const month = currentDate.clone().add(i, 'month').format('MMM').toLowerCase();
				const year = currentDate.clone().add(i, 'month').format('YYYY').toLowerCase();
				this.$set(this.values_sell, monthYear, 0);
				this.$set(this.resultado, monthYear, 0);
				this.$set(this.resultado_growth, monthYear, {valor:0, mes: month, ano: year});
			}
		},

		async findProduct(product, index){
			const isOpen = this.isOpen[index];

			// Fechar todos os cards se o card atual já estiver aberto
			if (isOpen) {
				this.isOpen = this.isOpen.map(() => false);
			} else {
				// Se o card atual não estiver aberto, fechar todos os cards e abrir o atual
				this.isOpen = this.isOpen.map(() => false);
				this.$set(this.isOpen, index, true);
			}
			let formData = new FormData();
			// this.$route.params
			formData.append("product", product);
			formData.append("year", this.currentYear);

			this.$http
				.post(`/scenarios/purchase/qtd/find_product`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {

						// console.log(response.data.findProduct)
						this.values_sell = []
						// this.getPurchaseQtds();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		monthsDisable() {
			if (this.view_months == false) {
				this.view_months = true;
				this.view_months_btn = false;
				this.view_months_btn_clear = true;
			} else {
				this.view_months = false;
				this.view_months_btn = true;
				this.view_months_btn_clear = false;
			}
		},

		setSimulation(){
			const url = this.$route.path;
			const simulationMatch = url.match(/simulation\/(\d+)/);
			if (simulationMatch && simulationMatch[1]) {
				this.simulation_id = simulationMatch[1];
			}
		},

		clearSelection(){
			this.productSelected = null
		},

		async getPocessesForSimulation(closeModals = false) {
            this.$swal.showLoading();
			await this.$http
			.get(`/s&op/simulation_processes`, {
				params: {
					simulation_id: this.simulation_id,
					productSelected: this.productSelected,
					monthSelected: this.monthSelected,
				}
			})
			.then((response) => {
				if (response.data.error) {
					this.goError(response.data.message);
				} else {
					if (closeModals) {
						this.isOpen = []
					}
					this.simulationProductList = response.data.processes;
				}
				this.$swal.close();
			})
			.catch(function (error) {
				console.log(error);
				this.$swal.close();
			});
		},

		async getProductsOptions(){
			await this.$http
			.get(`/products/allProducts`)
			.then((response) => {
				if (response.data.error) {
					this.goError(response.data.message)
				} else {
					let productRes = response.data.productsFactor
					// Remover duplicatas com tamanhos diferentes:
					productRes = productRes.filter((value, index, self) => {
						return self.findIndex((t) => t.code === value.code && t.name === value.name) === index;
					});
					// Ordenar por "code"
					productRes = productRes.sort((a, b) => {
                    if (a.code < b.code) {return -1}
                    if (a.code > b.code) {return 1}
                    return 0
                });
					this.productOptions = productRes.map(e=> ({label: `${e.code} - ${e.name} `, key: e.id}))
				}
			})
		},

		// getChartAtingimento() {
		// 	this.dataAtingimento = {
		// 		labels: this.months.map((item) => item),

		// 		datasets: [
		// 			{
		// 				label: "",
		// 				data: this.values_chart.map((item)=>item),
		// 				backgroundColor: "#f87979",
		// 				borderColor: "#f87979",
		// 				fill: false,
		// 				pointStyle: "circle",
		// 			},
		// 		],
		// 	};

		// 	this.optionsPie = {
		// 		responsive: true,
		// 		legend: {
		// 			display: false,
		// 		},
		// 	};
		// },

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getPurchaseQtds() {
			await this.$http
				.get(`/scenarios/simulation/${this.obj.id}/purchase/quantity`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						let purchase = response.data.purchase_qtd_simulation;
						this.purchases_qtd = purchase
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async getPurchaseValues() {
			await this.$http
				.get(`/scenarios/simulation/${this.obj.id}/purchase/quantity`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						let purchase = response.data.purchase_qtd_simulation;
						this.purchases_qtd = purchase
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},



	},

	computed: {
		getFormattedData() {
			const formattedData = {};

			// this.growth.forEach(item => {
			// 	const fieldName = `${item.month_year}`;
			// 	formattedData[fieldName] = {valor: item.growth, mes: item.date, ano: item.year}; // Valor inicial de 0
			// })

			return formattedData;
		},

		// valuesGrowthQtde(){
		// 	return this.growth.forEach(item => {
		// 		const fieldName = `${item.month_year}`;

		// 		// Verifica se o item está presente em this.resultado_growth
		// 		const resultadoItem = this.resultado_growth[fieldName];

		// 		if (resultadoItem) {
		// 			// Se estiver presente, substitui os valores pelos valores de this.growth
		// 			resultadoItem.valor = item.growth;
		// 			resultadoItem.mes = item.date;
		// 			resultadoItem.ano = item.year;
		// 		} else {
		// 			// Se não estiver presente, adiciona o item de this.growth a this.resultado_growth
		// 			this.$set(this.resultado_growth, fieldName, { valor: item.growth, mes: item.date, ano: item.year });
		// 		}
		// 	});
		// },

		totalItensFiltrados() {
			return this.simulationProductList.length;
		},

		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.simulationProductList.slice(inicio, fim);
		},
		orderedResultado() {
			const sortedKeys = Object.keys(this.resultado).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado[key];
			});

			return ordered;
		},

		orderedFactors() {
			let factors = 0;
			if(this.values_sell == ''){
				factors = this.resultado
			} else {
				factors = this.values_sell
			}
			const sortedKeys = Object.keys(factors).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = factors[key];
			});

			return ordered;
		},

		orderedFactorsGrowth() {
			const sortedKeys = Object.keys(this.resultado_growth).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado_growth[key].valor;
			});

			return ordered;
		},

		values_chart() {
			const sortedKeys = Object.keys(this.getFormattedData).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo array ordenado apenas com os valores
			const orderedValues = sortedKeys.map(key => this.getFormattedData[key].valor);

			return orderedValues;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
